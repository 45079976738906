import { useContext, useEffect } from "react";
import cxs from "cxs";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { PerksScreens } from "../../../helpers/constants";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { getIcon } from "../../../helpers/getIcon";
import PerkIcon from "../../MPPerkIcon";
import { isFromMobileWebView } from "../../../services/header-footer";
import "./index.scss";
import { AppWindow } from "../../../helpers/AppWindow";

const MPPerksSelectionSuccess = () => {
  const { contentState } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { selectedPerkBenefits, selectedMilestonePerk } = perksState;

  const {
    backgroundColor,
    titleColor,
    title,
    buttonBackgroundColor,
    buttonText,
    singlePerkColor,
    singlePerkText,
    multiplePerkColor,
    multiplePerkText,
    iconBackgroundColor,
    iconColor,
  } = contentState?.Content[1]?.perkSelection?.congratulationsSelection || {};

  const subtitleText =
    selectedPerkBenefits.length > 1
      ? multiplePerkText?.replace("{milestone}", selectedMilestonePerk?.milestoneName ?? "") ??
        "You've succesfully selected your {milestone} Milestone perks."?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        )
      : singlePerkText?.replace("{milestone}", selectedMilestonePerk?.milestoneName ?? "") ??
        "You've succesfully selected your {milestone} Milestone perk."?.replace(
          "{milestone}",
          selectedMilestonePerk?.milestoneName ?? ""
        );

  const { iconBorderColor: selectedIconBorderColor } =
    contentState?.Content[1]?.perkSelection?.perk?.perkSelected || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #FFF)`,
      paddingTop: isFromMobileWebView() ? "5rem" : "3rem",
    }),
    header: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({
      color: `var(--${selectedPerkBenefits.length > 1 ? multiplePerkColor : singlePerkColor}, #2e2e2e)`,
    }),
    iconContainer: cxs({
      color: `var(--${iconColor}, #03314F)`,
      borderColor: `var(--${selectedIconBorderColor}, #00446A)`,
      backgroundColor: `var(--${iconBackgroundColor}, #00446A)`,
    }),
    confirmButton: cxs({
      color: `#fff`,
      backgroundColor: `var(--${buttonBackgroundColor}, #03314f)`,
    }),
  };

  const appWindow = window as AppWindow;

  const setToolbarVisibility = (visible: boolean) => {
    if (appWindow.AppInterface && appWindow.AppInterface.setToolbarVisibility) {
      appWindow.AppInterface.setToolbarVisibility(visible);
    }
    if (appWindow.webkit?.messageHandlers?.setToolbarVisibility) {
      appWindow.webkit.messageHandlers.setToolbarVisibility.postMessage(visible);
    }
  };

  useEffect(() => {
    setToolbarVisibility(false);
  }, []);

  const handleGoToPerks = async () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.Hub },
    });
  };

  return (
    <div className={`perksSuccessPage__container ${styles.container}`} data-testid="perks-selection-success">
      <div className="perksSuccessPage__headerContainer">
        <div className="perksSuccessPage__icons">
          {selectedPerkBenefits.map((perk) => {
            const icon = getIcon(perk.perkBenefitId, list, defaultCategory, defaultIconName);
            return (
              <PerkIcon
                testId="perk-icon"
                key={perk.perkBenefitId}
                icon={icon.name}
                category={icon.category}
                variant={"solid"}
                iconContainerClassName={styles.iconContainer}
                size="large"
              />
            );
          })}
        </div>
        <div className="perksSuccessPage__headerText">
          <h1 className={`perksSuccessPage__title ${styles.header}`}>{title}</h1>
          <p className={`perksSuccessPage__subtitle ${styles.subtitle}`}>{subtitleText}</p>
        </div>
      </div>
      <div className="perksSuccessPage__buttons">
        <RoundedButton
          testId="return-to-hub-button"
          className={styles.confirmButton}
          label={buttonText ?? "Return to Perks"}
          type="button"
          onClick={handleGoToPerks}
        />
      </div>
    </div>
  );
};

export default MPPerksSelectionSuccess;
