import { useContext } from "react";
import cxs from "cxs";
import PerkIcon from "../../../MPPerkIcon";
import { ACTIONS, PerksContext } from "../../../../context/PerksContext";
import { PerksScreens } from "../../../../helpers/constants";
import { ContentContext } from "../../../../context/ContentContext";
import { IMilestonePerk } from "../../../../types/perks";
import { replacePlaceholderName } from "../../../../helpers/replacePlaceholderName";
import { formatDate } from "../../../../helpers/formatDate";
import "./index.scss";

const MPSelectPerksCTA = (props: { milestone: IMilestonePerk }) => {
  const { perksDispatch } = useContext(PerksContext);
  const { contentState } = useContext(ContentContext);
  const { milestoneName, numberOfPerksSelectable, memberSelectionLst } = props.milestone;

  const {
    singlePerkTitle,
    multiPerksTitle,
    multiPerksSubTitle,
    singlePerkSubTitle,
    backgroundColor,
    titleColor,
    subTitleColor,
    leftIconColor,
    leftIconForegroundColor,
    ctaIcon,
    ctaIconCategory,
    ctaIconColor,
    leftIcon,
    leftIconCategory,
  } = contentState?.Content[0]?.selectPerks?.cta || {};

  const styles = {
    ctaContainer: cxs({
      backgroundColor: `var(--${backgroundColor}, #ffffff)`,
    }),
    title: cxs({ color: `var(--${titleColor}, #151515)` }),
    subtitle: cxs({ color: `var(--${subTitleColor}, #151515)` }),
    iconContainer: cxs({
      color: `var(--${leftIconColor}, #FFFFFF)`,
      backgroundColor: `var(--${leftIconForegroundColor}, #00446A)`,
      borderColor: `var(--${leftIconForegroundColor}, #00446A)`,
    }),
  };

  const actionIconStyles = {
    color: `var(--${ctaIconColor}, #2E2E2E)`,
  };

  const handleClick = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.PerksSelection },
    });
    perksDispatch({
      type: ACTIONS.SET_SELECTED_MILESTONE_PERK,
      value: props.milestone,
    });
  };

  const displayTitle = () => {
    const title = numberOfPerksSelectable === 1 ? singlePerkTitle : multiPerksTitle;
    return replacePlaceholderName(title || "", { milestoneName });
  };

  const displaySubtitle = () => {
    const subtitle = numberOfPerksSelectable === 1 ? singlePerkSubTitle : multiPerksSubTitle;
    const numberOfPerksSelectableStr = numberOfPerksSelectable.toString();
    const selectExpirationDateStr = memberSelectionLst[0].selectionExpirationDate || "";

    const replacements = {
      milestoneName: milestoneName,
      numberOfPerksSelectable: numberOfPerksSelectableStr,
      selectionExpirationDate: formatDate(selectExpirationDateStr) || "",
    };

    return replacePlaceholderName(subtitle || "", replacements);
  };

  return (
    <div onClick={handleClick} className={`ctaCard__container ${styles.ctaContainer}`} data-testid="select-perk-cta">
      <div className="ctaCard__leftInfo">
        <PerkIcon
          category={leftIconCategory ?? ""}
          icon={leftIcon ?? ""}
          iconContainerClassName={styles.iconContainer}
          variant="solid"
          testId="select-perk-icon"
          size="large"
        />
        <div className="ctaCard_textContainer">
          <div className={`ctaCard__title ${styles.title}`} data-testid="select-perk-title">
            {displayTitle()}
          </div>
          <p className={`ctaCard__description ${styles.subtitle}`} data-testid="select-perk-subtitle">
            {displaySubtitle()}
          </p>
        </div>
      </div>
      <div className="ctaCard__rightAction" data-testid="select-perk-action">
        <auro-icon style={actionIconStyles} name={ctaIcon} category={ctaIconCategory} customColor customSize />
      </div>
    </div>
  );
};

export default MPSelectPerksCTA;
