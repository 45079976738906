import axios from "axios";
import { MemberBenefitChoice, ASLoginUrl, MP100KPath } from "../helpers/constants"
import jwtDecode from "jwt-decode";
import { appInsights } from "../services/AppInsights";
import {AccountStateType} from "../state/InitialState";

 export type MPMember =
    {
        FirstName : string,
        LastName : string,
        MPNumber : string,
        EmailAddress : string,
    }

export type EnrollPerksRequest = MPMember &
{
    EnrollPerkId : number,
    MilestoneId : number,
    Nominee? : MPMember,
    AccountGuid : string
}

const PerksEnrollmentTransaction = async (accountState :AccountStateType, perkId : number, nominee: MPMember) : Promise<boolean> => {
    const EnrollPerksEndpointUrl = process.env.REACT_APP_MPPERKS_ENROLLMENT_ENDPOINT as string;
    const accountDetails = accountState.MileagePlanDetails;
    const request : EnrollPerksRequest = {
        FirstName : accountDetails.FirstName,
        LastName : accountDetails.LastName,
        MPNumber : accountDetails.MileagePlanNumber,
        EmailAddress : accountDetails.Email,
        EnrollPerkId: perkId,
        MilestoneId : accountDetails.MilestoneId,
        Nominee : nominee,
        AccountGuid: accountState.Token.Guid
    }
    try {
    const response  = await axios.post(
        EnrollPerksEndpointUrl,
        {...request},
        {
            headers: {
                Authorization: `Bearer ${accountState.Token.Value}`,
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY as string,
            },
        }
    );
    console.log("API RESPONSE", response.status);
    return response.status == 200;
} catch (error) {
    console.error("Error fetching data:", error);
    throw error;
    }
};

export default PerksEnrollmentTransaction;