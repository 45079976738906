import React, { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from "react";
import { initialContentState, ContentStateType } from "../state/InitialState";
import UserAccountContext from "./UserAccountContext";
import ContentService from "../services/ContentService";
import { appInsights } from "../services/AppInsights";

export type ContentContextType = {
  contentState: ContentStateType;
  contentStateDispatch: any;
};

export const ContentContext = createContext<ContentContextType>({
  contentState: initialContentState,
  contentStateDispatch: () => {},
});

const ACTIONS = {
  SET_CONTENT: "SET_CONTENT",
  SET_ERROR: "SET_ERROR",
  SET_LOADING_DATA: "SET_LOADING_DATA",
};

type ActionType =
  | { type: string; value: (typeof initialContentState)["Content"] | null }
  | { type: string; value: (typeof initialContentState)["Error"] | null }
  | { type: string; value: boolean };

const contentReducer = (state: ContentStateType, action: ActionType): ContentStateType => {
  switch (action.type) {
    case ACTIONS.SET_CONTENT:
      return {
        ...state,
        Content: action.value as (typeof initialContentState)["Content"],
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        Error: action.value as (typeof initialContentState)["Error"],
      };
    case ACTIONS.SET_LOADING_DATA:
      return {
        ...state,
        isLoadingMobileContent: action.value as unknown as typeof initialContentState.isLoadingMobileContent,
      };
    default:
      return state;
  }
};

const ContentProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { accountState } = useContext(UserAccountContext);
  const [contentState, contentStateDispatch] = useReducer(contentReducer, initialContentState);
  const contextValue: ContentContextType = useMemo(
    () => ({
      contentState,
      contentStateDispatch,
    }),
    [contentState, contentStateDispatch]
  );

  useEffect(() => {
    const getContentData = async () => {
      contentStateDispatch({ type: ACTIONS.SET_LOADING_DATA, value: true });
      await ContentService()
        .then((res) => {
          appInsights?.trackEvent({
            name: "BFF Service for pulling mobile content service perks API called",
          });
          if (!res) {
            throw new Error("No response from the Content service.");
          }
          contentStateDispatch({ type: "SET_CONTENT", value: res.data });
          contentStateDispatch({ type: ACTIONS.SET_LOADING_DATA, value: false });
        })
        .catch((ex) => {
          appInsights.trackException({ exception: ex });
          contentStateDispatch({
            type: "SET_ERROR",
            value: new Error("Unable to retrieve your Content data.") as any,
          });
        });
    };
    getContentData();
  }, [accountState]);

  return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

export default ContentProvider;
