import "./styles.scss";
import { PerksScreens } from "../../../helpers/constants";
import ContentViewer from "../../ContentViewer";
import Nav from "../../MPPerksNavigation";
import { useContext, useEffect, useState } from "react";
import { replacePlaceholderName } from "../../../helpers/replacePlaceholderName";
import RoundedButton from "../../RoundedButton/RoundedButton";
import cxs from "cxs";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";

type RedemptionInstructionData = {
  title: string;
  href: string;
  order: number;
  redeemInstructionRichText: string;
  redeemInstructionCtaHandling: string;
};

//create an interface called ITermsAndConditionViewerProps
interface ITermsAndConditionViewerProps {
  title?: string;
  data?: RedemptionInstructionData;
  placeholderNameObj?: object;
}

const TermsAndConditionViewer = (props: ITermsAndConditionViewerProps) => {
  const { title, data, placeholderNameObj } = props;
  const { redeemInstructionRichText, redeemInstructionCtaHandling } = data ?? {};
  const [termsAndCondition, setTermsAndCondition] = useState<any>(null);
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { ctaEnabledBackgroundColor, ctaEnabledColor } = contentState?.Content[1]?.perkSelection || {};

  const styles = {
    backButton: cxs({
      color: `var(--${ctaEnabledColor}, #FFFFFF)`,
      backgroundColor: `var(--${ctaEnabledBackgroundColor}, #03314F)`,
    }),
  };

  const handleGoToPerks = async () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.Hub },
    });
  };

  useEffect(() => {
    if (redeemInstructionRichText && redeemInstructionCtaHandling === "onClick") {
      setTermsAndCondition({
        memo: replacePlaceholderName(redeemInstructionRichText, {
          ...placeholderNameObj,
        }),
      });
    }
  }, [redeemInstructionRichText]);

  return (
    <div className="content-viewer">
      <Nav
        title={title ?? "Terms and Conditions"} // use the title of use the default "Terms and Conditions"
        returnToScreen={PerksScreens.Hub}
        customBackgroundColor="var(--ds-color-excursion-blue-900, #03314F)"
      />
      <ContentViewer content={termsAndCondition?.memo}>
        {perksState.activeScreen?.previousScreen !== PerksScreens.PerksSelection && (
          <div>
            <div className="terms_and_conditions_back_button">
              <RoundedButton
                label={contentState?.Content[3]?.perksLanding?.backButtonText ?? "Back to Alaska perks"}
                className={styles.backButton}
                onClick={handleGoToPerks}
                type="button"
                testId="terms_and_conditions_back_button"
              />
            </div>
          </div>
        )}
      </ContentViewer>
    </div>
  );
};

export default TermsAndConditionViewer;
