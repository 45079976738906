import { useContext } from "react";
import cxs from "cxs";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { PerksScreens } from "../../../helpers/constants";
import RoundedButton from "../../RoundedButton/RoundedButton";
import { getIcon } from "../../../helpers/getIcon";
import PerkIcon from "../../MPPerkIcon";
import { isFromMobileWebView } from "../../../services/header-footer";
import "./index.scss";
import {PerkRedemptionScreen, RedemptionFlow} from "../MPPerksRedemption";
import ContentViewer from "../../ContentViewer";

const MPPerksRedemptionSuccess = () => {
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch } = useContext(PerksContext);
    const {
        backgroundColor,
        titleColor,
        buttonBackgroundColor,
        singlePerkColor,
        iconBackgroundColor,
        iconColor,
    } = contentState?.Content[1]?.perkSelection?.congratulationsSelection || {};

    const currentBenefit = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);
    
    const perksArray = contentState?.Content[8]?.perksRedemptionPage?.perks;
    
    const contentFromContentStack : any = perksArray?.find(perk => perk.perkId === currentBenefit?.perkBenefitId.toString());

    const { iconBorderColor: selectedIconBorderColor } =
    contentState?.Content[1]?.perkSelection?.perk?.perkSelected || {};

    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const styles = {
        container: cxs({
            background: `var(--${backgroundColor}, #03314F)`,
            paddingTop: isFromMobileWebView() ? "5rem" : "3rem",
        }),
        header: cxs({ color: `var(--${titleColor}, #151515)` }),
        subtitle: cxs({
            color: `var(--${ singlePerkColor}, #2e2e2e)`,
        }),
        iconContainer: cxs({
            color: `var(--${iconColor}, #03314F)`,
            borderColor: `var(--${selectedIconBorderColor}, #2CD7E1)`,
            backgroundColor: `var(--${iconBackgroundColor}, #2CD7E1)`,
        }),
        confirmButton: cxs({
            color: `#fff`,
            backgroundColor: `var(--${buttonBackgroundColor}, #03314f)`,
        }),
    };

    const handleGoToPerks = async () => {
        perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: { screenName: PerksScreens.Hub },
        });
    };
    
    const currentPerk = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId);
    const icon = getIcon(currentPerk!.perkBenefitId, list, defaultCategory, defaultIconName);
    const page = contentFromContentStack.redemptionSuccessPage
    ;
    return (
        <div className={`perksSuccessPage__container ${styles.container}`} data-testid="perks-selection-success">
            <div className="perksSuccessPage__headerContainer">
                <div className="perksSuccessPage__icons">
                    {
                            <PerkIcon
                                testId="perk-icon"
                                key={currentPerk!.perkBenefitId}
                                icon={icon.name}
                                category={icon.category}
                                variant={"solid"}
                                iconContainerClassName={styles.iconContainer}
                                size="large"
                            />
                    }
                </div>
                <div className="perksSuccessPage__headerText">
                    <h1 className={`perksSuccessPage__title ${styles.header}`}>{page?.successTitle}</h1>
                    <p className={`perksSuccessPage__subtitle ${styles.subtitle}`}>
                        <ContentViewer content={page?.successSubtitle}/>
                    </p>
                </div>
            </div>
            <div className="perksSuccessPage__buttons">
                <RoundedButton
                    testId="return-to-hub-button"
                    className={styles.confirmButton}
                    label={page?.successBackButton ?? "Return to Perks"}
                    type="button"
                    onClick={handleGoToPerks}
                />
            </div>
        </div>
    );
};

export default MPPerksRedemptionSuccess;