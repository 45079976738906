import axios from "axios";

const baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/1/Retain/MileagePlan/mpchoice/api`;

export const validateNominee = async (nomineeMPNumber : string, token: string, guid: string) =>
{
    const nomineeServiceUrl = baseURL + "/Member/Nominee";
    
    try{
        return await axios.get(nomineeServiceUrl,
            {
                params: {
                    mpNumber: nomineeMPNumber,
                    accountGuid: guid,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY as string,
                }
            }
        );
    }
    catch (error) {
        throw error
    }
}