import React, { useEffect } from "react";
import "./styles.scss";

const CustomLoadingSpinner = () => {
  useEffect(() => {
    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  return (
    <div className="custom-spinner-container">
      <div className="custom-spinner" data-testid="custom-spinner" />
    </div>
  );
};

export default CustomLoadingSpinner;
