import "./index.scss";
import { useContext } from "react";
import cxs from "cxs";
import PerkIcon from "../../../MPPerkIcon";
import { formatNumber } from "../../../../helpers/formatNumber";
import { getIcon } from "../../../../helpers/getIcon";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../../context/PerksContext";
import { isNumeric } from "../../../../helpers/isNumeric";
import { isStringContainsDate } from "../../../../helpers/isStringContainsDate";
import { formatDate } from "../../../../helpers/formatDate";
import { PerksScreens } from "../../../../helpers/constants";
import { extractKeysAndCreateObject } from "../../../../helpers/extractKeysAndCreateObj";
import { replacePlaceholderName } from "../../../../helpers/replacePlaceholderName";
import { AppWindow } from "../../../../helpers/AppWindow";
import { IFulfillmentPerkDetails, PerkFulfillmentState } from "../../../../types/perks";

export interface MPPerkCardProps {
  perkBenefitId: number;
  selectionDate?: string;
  indx: number;
  benefitExpirationDate?: string;
}

interface Field {
  title: string;
  value: string;
  order: number | string;
  state?: string;
}

interface LinkType {
  title: string;
  href: string;
  order: number | string;
  redeemInstruction?: string;
  state?: string;
  redeemInstructionRichText?: string;
  redeemInstructionCtaHandling?: string;
  webViewTitle?: string;
}

export default function MPPerkCard(perk: Readonly<MPPerkCardProps>) {
  const {
    perksState: { perks, perkFulfillmentStates },
    perksDispatch,
  } = useContext(PerksContext);

  const appWindow = window as AppWindow;
  const handleClick = (value: string) => {
    if (appWindow.AppInterface && appWindow.AppInterface.sendStringValue) {
      appWindow.AppInterface.sendStringValue(value);
    }

    if (appWindow.webkit?.messageHandlers?.sendStringValue) {
      appWindow.webkit.messageHandlers.sendStringValue.postMessage(value);
    }
  };

  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const {
    cardBackgroundColor,
    perkTitleColor,
    fieldValueColor,
    linkColor,
    fieldTitleColor,
    iconBackgroundColor,
    iconColor,
  } = contentState?.Content[3]?.perksLanding?.pastPerks || {};

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const styles = {
    cardContainer: cxs({
      background: `var(--${cardBackgroundColor}, #fff)`,
    }),
    perkTitle: cxs({
      color: `var(--${perkTitleColor}, #2E2E2E)`,
    }),
    fieldValue: cxs({
      color: `var(--${fieldValueColor}, #151515)`,
    }),
    fieldTitle: cxs({
      color: `var(--${fieldTitleColor}, #151515)`,
    }),
    link: cxs({
      color: `var(--${linkColor}, #03314F)`,
    }),
    icon: cxs({
      backgroundColor: `var(--${iconBackgroundColor}, #00446A)`,
      color: `var(--${iconColor}, #fff)`,
    }),
  };

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk.id === perkId);

    if (perk) {
      return perk;
    }
  };

  const toTitleCase = (str: string) => {
    return str
      ?.toLowerCase()
      .split(" ")
      .map((title: string) => title.charAt(0).toUpperCase() + title.slice(1))
      .join(" ");
  };

  const getFormattedValue = (value: string, selected: Field): string => {
    if (isStringContainsDate(selected.value)) {
      return formatDate(value) ?? "";
    }

    if (isNumeric(value)) {
      return formatNumber(value) ?? "";
    }

    return value ?? "";
  };

  const getCardStateText = (fields: Field[] | undefined) => {
    if (!fields) return;

    const fulfillmentDetails: IFulfillmentPerkDetails | undefined = perkFulfillmentStates.find(
      (fulfillmentPerk) => fulfillmentPerk.perkBenefitId === perk.perkBenefitId
    );
    if (!fulfillmentDetails) return fields; //if there are no perks with redemption states, there is no need to search for specific content based on redemption state
    //logic to add 365 days to selection Date on "redeemBy field" here
    const fulfillmentStateString: string = PerkFulfillmentState[fulfillmentDetails.fulfillmentState]; //reverse mapping to get name of enum state field
    return fields.filter((field) => field.state == fulfillmentStateString || !field.state);
  };

  function isExpired(benefitExpirationDate: Date): boolean {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return benefitExpirationDate < today;
  }

  const getCardStateElements = (links: LinkType[] | undefined) => {
    if (!links) return;

    const fulfillmentDetails: IFulfillmentPerkDetails | undefined = perkFulfillmentStates.find(
      (fulfillmentPerk) => fulfillmentPerk.perkBenefitId === perk.perkBenefitId
    );
    if (!fulfillmentDetails) return links; //if there are no perks with redemption states, there is no need to search for specific content based on redemption state

    const fulfillmentStateString: string = PerkFulfillmentState[fulfillmentDetails.fulfillmentState]; //reverse mapping to get name of enum state field
    return links.filter((link) => link.state == fulfillmentStateString || !link.state);
  };

  const handleRedemption = (nextScreen: string, screenText?: string, screenTitle?: string) => {
    perksDispatch({ type: ACTIONS.SET_ACTIVE_REDEMPTION, value: { perkBenefitId: perk.perkBenefitId } });
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: nextScreen, data: screenText, title: screenTitle },
    });
  };

  const perksContent = getPerk(perk.perkBenefitId);

  const handleTermsAndConditionsClick = (
    data: any,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    resultObject: Record<string, unknown>
  ) => {
    event.preventDefault();
    // call dispatch to set active screen, passing a custom title to the screen, and also can pass a custom data that can be access from the props of the screen
    perksDispatch({
      type: "SET_ACTIVE_SCREEN",
      value: {
        ...{ screenName: PerksScreens.TermsAndCondition, title: data?.title },
        ...{ data },
        ...{ placeholderNameObj: resultObject },
      },
    });
  };

  //const links: LinkType[] | undefined = perksContent?.pastPerksCardProps?.links;

  //needs to be in useEffect with depencency on fulfillmentStates
  const links: LinkType[] | undefined = getCardStateElements(perksContent?.pastPerksCardProps?.links);
  const fields: Field[] | undefined = getCardStateText(perksContent?.pastPerksCardProps?.fields);

  return (
    <div className={`perk-card ${styles.cardContainer}`} data-testid={`past-perk-card-${perk.indx}`} key={perk.indx}>
      <PerkIcon
        variant="solid"
        iconContainerClassName={styles.icon}
        icon={getIcon(perk.perkBenefitId, list || [], defaultCategory, defaultIconName).name}
        category={getIcon(perk.perkBenefitId, list || [], defaultCategory, defaultIconName).category}
      />
      <div className="perk-card-data">
        <div className={`perk-card-title ${styles.perkTitle}`}>{perksContent?.perkSummary}</div>

        {fields?.length ? (
          <div>
            {fields
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((selected, index) => {
                const value = perks.pastPerksList[perk.indx][selected.value] as string;
                const formattedValue = getFormattedValue(value, selected);
                let fieldValue = formattedValue;
                if (selected.title === "Redeem by" || selected.title === "Gift by") {
                  const expirationDate = new Date(formattedValue);
                  fieldValue = new Date(
                    expirationDate.getFullYear() + 1,
                    expirationDate.getMonth(),
                    expirationDate.getDate()
                  ).toLocaleDateString();
                }

                return (
                  <div className="field-container" key={index}>
                    <div className={`${styles.fieldTitle}`}>{selected.title}</div>
                    <div className={`${styles.fieldValue}`}>{fieldValue}</div>
                  </div>
                );
              })}
          </div>
        ) : null}

        {links?.length ? (
          <div className="perk-card-link-container">
            {links
              .sort((a, b) => Number(a.order) - Number(b.order))
              .map((link, index) => {
                return link.redeemInstructionCtaHandling?.toLowerCase() === "onclick" ? (
                  <a
                    className={`perk-card-link ${styles.link}`}
                    key={index}
                    rel="noreferrer"
                    onClick={(event) =>
                      link.href
                        ? handleRedemption(link.href, link.redeemInstructionRichText as string, link.webViewTitle)
                        : (() => {
                            handleClick(link.webViewTitle ?? "");
                            handleTermsAndConditionsClick(
                              link,
                              event,
                              extractKeysAndCreateObject(
                                link.redeemInstructionRichText as string,
                                perks.pastPerksList[perk.indx]
                              )
                            );
                          })()
                    }
                  >
                    {link.title}
                  </a>
                ) : (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a
                    className={`perk-card-link ${styles.link}`}
                    key={index}
                    target={
                      perksContent?.perkName?.includes("LOUNGE_DAY") &&
                      window.navigator.userAgent.toUpperCase().includes("ALKAPP/IOS")
                        ? "_self"
                        : link.redeemInstructionCtaHandling ?? "_blank"
                    }
                    href={replacePlaceholderName(
                      link.href,
                      extractKeysAndCreateObject(link.href, perks.pastPerksList[perk.indx])
                    )}
                    onClick={() => handleClick(link.webViewTitle ?? "")}
                  >
                    {link.title}
                  </a>
                );
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
