import "@aurodesignsystem/auro-loader";
import "./styles.scss";
import CustomLoadingSpinner from "./CustomSpinner";
import { isFromMobileWebView } from "../../services/header-footer";

const Spinner = (): JSX.Element => {
  return isFromMobileWebView() ? (
    <CustomLoadingSpinner />
  ) : (
    <div className="spinner-container">
      <auro-loader orbit lg></auro-loader>
    </div>
  );
};

export default Spinner;
