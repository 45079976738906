import { PerksScreens } from "../helpers/constants";
import {
  IActiveScreen,
  IMilestonePerk,
  IPerkBenefit,
  IPerksResponse,
  IFulfillmentPerkDetails,
  IActiveRedemption,
} from "../types/perks";

export type initialStateType = {
  activeScreen?: IActiveScreen;
  isLoadingPerkData?: boolean;
  selectedMilestonePerk: null | IMilestonePerk;
  selectedPerkBenefits: IPerkBenefit[];
  perks: IPerksResponse;
  perkFulfillmentStates: IFulfillmentPerkDetails[];
  activeRedemption: null | IActiveRedemption;
};

export const initialState = {
  activeScreen: {
    screenName: PerksScreens.Hub,
  } as IActiveScreen, // Default screen is Perks Hub
  isLoadingPerkData: true,
  selectedMilestonePerk: null,
  selectedPerkBenefits: [],
  perkFulfillmentStates: [],
  activeRedemption: null,
  perks: {
    currentMiles: null,
    upcomingMilestones: {
      nextMilestone: "20K",
      nextMilestoneMile: 20000,
      futureMilestone: "30K",
      futureMilestoneMile: 30000,
    },
    pastPerksList: [
      {
        perkBenefitId: 22,
        milestoneId: 1,
        fulfillmentDate: "2024-10-14T04:22:07.0621949",
        perkName: "WIFI_SINGLE_FLIGHT_PASS_1",
        perkDescription: "Wifi Pass 1 Flag",
        isEnabled: true,
        selectionDate: "2024-10-14T04:22:07.0621949",
        milestoneRequiredMiles: 10000,
        benefitExpirationDate: "0001-01-01T00:00:00",
        perksFulfillmentKey: "WIFI_SINGLE_FLIGHT_PASS_11994241014",
      },
      {
        perkBenefitId: 29,
        milestoneId: 5,
        fulfillmentDate: "2024-10-14T04:22:07.0621949",
        perkName: "LOUNGE_DAY_PASS_2",
        perkDescription: "Lounge Pass 2 Flag",
        isEnabled: true,
        selectionDate: "2024-10-03T01:53:57.7712119",
        milestoneRequiredMiles: 100000,
        benefitExpirationDate: "2025-10-03T01:53:57.771",
      },
    ],
    availableMilestonePerks: [],
    currentPerks: {
      milestoneId: 0,
      milestoneRequiredMiles: 10000,
      milestoneName: "10K",
      numberOfPerksSelectable: 1,
      perkBenefitsLst: [],
    },
    nextPerks: {
      milestoneId: 9,
      milestoneRequiredMiles: 20000,
      milestoneName: "20K",
      numberOfPerksSelectable: 0,
      perkBenefitsLst: [
        {
          perkBenefitId: 31,
          perkName: "25_PERCENT_BONUS_MILES",
          perkDescription: "25% Bonus Miles",
          isEnabled: true,
          benefitDuration: null,
          fulfillmentSystemId: null,
          fulfillmentSystem: null,
          memberSelections: [],
          milestonePerks: [],
        },
      ],
    },
    futurePerks: {
      milestoneId: 2,
      milestoneRequiredMiles: 30000,
      milestoneName: "30K",
      numberOfPerksSelectable: 1,
      perkBenefitsLst: [
        {
          perkBenefitId: 13,
          perkName: "2500_BONUS_MILES",
          perkDescription: "2500 BONUS MILES",
          isEnabled: true,
          benefitDuration: null,
          fulfillmentSystemId: null,
          fulfillmentSystem: null,
          memberSelections: [],
          milestonePerks: [],
        },
        {
          perkBenefitId: 21,
          perkName: "25_DOLLARS_DISCOUNT_CODE",
          perkDescription: "25 Discount Code Flag",
          isEnabled: true,
          benefitDuration: null,
          fulfillmentSystemId: null,
          fulfillmentSystem: null,
          memberSelections: [],
          milestonePerks: [],
        },
        {
          perkBenefitId: 23,
          perkName: "WIFI_SINGLE_FLIGHT_PASS_4",
          perkDescription: "Wifi Pass 4 Flag",
          isEnabled: true,
          benefitDuration: null,
          fulfillmentSystemId: 2,
          fulfillmentSystem: null,
          memberSelections: [],
          milestonePerks: [],
        },
        {
          perkBenefitId: 38,
          perkName: "TRY_GOLD_FOR_A_TRIP",
          perkDescription: "Try Gold for a trip",
          isEnabled: true,
          benefitDuration: null,
          fulfillmentSystemId: 1,
          fulfillmentSystem: null,
          memberSelections: [],
          milestonePerks: [],
        },
      ],
    },
    allMilestonePerks: [],
    errorMessage: "",
    errorCode: "",
  },
};
