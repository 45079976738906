import "./index.scss";

export type PerkIconProps = {
  category: string;
  icon: string;
  iconContainerClassName?: string;
  variant: "solid" | "twoTone" | "outline";
  size?: "large";
  testId?: string;
};

const MPPerkIcon = (props: PerkIconProps) => {
  const { category, icon, variant, iconContainerClassName, size, testId } = props;

  return (
    <div className={`perkIcon__container ${variant} ${size}`} data-testid={testId}>
      <div className={`perkIcon___foreground ${iconContainerClassName}`}>
        <auro-icon category={category} name={icon} customColor customSize />
      </div>
      <div className="perkIcon__background" />
    </div>
  );
};

export default MPPerkIcon;
