import "./index.scss";
import { useContext, useEffect } from "react";
import MPPerkCard from "../MPPerkCard";
import { ACTIONS, PerksContext } from "../../../../context/PerksContext";
import { IFulfillmentPerkDetails, IPastPerk, PerkFulfillmentState } from "../../../../types/perks";

export interface PastPerksList {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
  milestoneRequiredMiles: number | string;
  selectionDate: string;
}

const determineRedemptionState = (fulfillmentDate: string, selectionDate: string, benefitExpirationDate: string) => {
  const currentDate: Date = new Date();
  const selectionDateTime: Date = new Date(selectionDate);

  const redemptionExpirationDate: Date = new Date(
    selectionDateTime.getFullYear() + 1,
    selectionDateTime.getMonth(),
    selectionDateTime.getDate()
  );

  if (!fulfillmentDate && redemptionExpirationDate < currentDate) return PerkFulfillmentState.ExpiredBeforeRedemption;

  if (!fulfillmentDate) return PerkFulfillmentState.PreFulfillment;

  const benefitEndDate: Date = new Date(benefitExpirationDate);

  if (benefitEndDate < currentDate) return PerkFulfillmentState.ExpiredAfterUse;

  return PerkFulfillmentState.FulfilledActive;
};

export default function MPPerkList() {
  const {
    perksState: { perks },
    perksDispatch,
  } = useContext(PerksContext);

  useEffect(() => {
    setRedemptionStates();
  }, [perks]);

  const setRedemptionStates = () => {
    const fulfillmentStates: IFulfillmentPerkDetails[] = perks.pastPerksList.map((perk: IPastPerk) => {
      const detail: IFulfillmentPerkDetails = {
        fulfillmentState: determineRedemptionState(
          perk.fulfillmentDate,
          perk.selectionDate,
          perk.benefitExpirationDate
        ),
        fulfillmentDate: perk.fulfillmentDate,
        perkBenefitId: perk.perkBenefitId,
      };
      return detail;
    });
    perksDispatch({ type: ACTIONS.SET_REDEMPTION_STATE, value: fulfillmentStates });
  };

  return (
    <>
      {perks.pastPerksList?.length > 0 && (
        <div>
          <div className="perk-heading">Perks you have chosen</div>
          <div className="perks-list">
            {perks.pastPerksList?.map((perk: PastPerksList, indx: number) => (
              <MPPerkCard key={indx} {...perk} indx={indx} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
