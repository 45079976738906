export interface IPerksResponse {
  currentMiles: string | null | number;
  upcomingMilestones: IUpcomingMilestones;
  pastPerksList: IPastPerk[];
  availableMilestonePerks: IMilestonePerk[];
  currentPerks: Perks;
  nextPerks: Perks;
  errorMessage: string | null;
  errorCode: string | null;
  futurePerks: IFuturePerks | null;
  allMilestonePerks: IAllMilestonePerks[];
}

export interface IFuturePerks {
  milestoneId: number;
  milestoneRequiredMiles: number;
  milestoneName: string;
  numberOfPerksSelectable: number;
  tierName?: string | null;
  oneWorldTier?: string | null;
  perkBenefitsLst: IPerkBenefit[];
}

export interface IAllMilestonePerks {
  milestoneId: number;
  milestoneRequiredMiles: number;
  milestoneName: string;
  tierName?: string | null;
  oneWorldTier?: string | null;
  perkBenefitsLst: IPerkBenefit[];
}

type UpcomingMilestones = {
  nextMilestone: string;
  nextMilestoneMile: number;
  futureMilestone: string;
  futureMilestoneMile: number;
};

type PerkBenefit = {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
};

type Perks = {
  milestoneId: number;
  milestoneRequiredMiles: number;
  milestoneName: string;
  numberOfPerksSelectable: number;
  tierName?: string | null;
  oneWorldTier?: string | null;
  perkBenefitsLst: PerkBenefit[];
};

export interface IPastPerk {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
  selectionDate: string;
  benefitExpirationDate: string;
  milestoneRequiredMiles: number;
  fulfillmentDate: string;
  milestoneId: number;
  perksFulfillmentKey?: string | undefined;
  [key: string]: string | number | boolean | undefined;
}

export interface IUpcomingMilestones {
  nextMilestone: string;
  nextMilestoneMile: number;
  futureMilestone: string;
  futureMilestoneMile: number;
}

export interface IMilestonePerk {
  milestoneName: string;
  numberOfPerksSelectable: number;
  milestoneRequiredMiles: number;
  memberSelectionLst: IMemberSelection[];
  perkBenefitsLst: IPerkBenefit[];
}

export interface IMemberSelection {
  memberSelectionId: number;
  mpNumber: string;
  milestoneId: number;
  perkBenefitId: number | null;
  selectionDate: string | null;
  selectionExpirationDate: string;
  fulfillmentDate: string | null;
  milestoneAchievementDate: string;
  milestoneAchievementId: string;
}

export interface IPerkBenefit {
  perkBenefitId: number;
  perkName: string;
  perkDescription: string;
  isEnabled: boolean;
  isSuccessfullyEnrolled?: boolean;
}

export interface IFulfillmentPerkDetails
{
  perkBenefitId: number,
  fulfillmentState : PerkFulfillmentState,
  fulfillmentDate?: string
}

export enum PerkFulfillmentState {
  PreFulfillment, //user has selected perk but has not chosen a start date
  FulfilledActive, //user has chosen perk and perk is usable
  ExpiredBeforeRedemption,
  ExpiredAfterUse
}

export interface NextPerk {
  milestoneRequiredMiles: number | null;
  milestoneName: string | null;
  numberOfPerksSelectable: number | null;
  perkBenefitsLst: IPerkBenefit[] | null;
}

export interface IGetPerksRequest {
  mileagePlanNumber: string;
  asoaMiles: number;
  guid: string;
}

export interface IEnrollPerksRequest {
  enrollPerkId: number;
  milestoneId: number;
  firstName: string;
  lastName: string;
  mpNumber: string;
  emailAddress: string;
  nominee: INominee;
  accountGuid: string;
}

export interface IGetPerkSelectionTokenRequest {
  milestoneId: number;
  mpNumber: string;
  accountGuid: string;
}

export interface IGetPerkSelectionTokenResponse {
  tokens: string[];
  message: string;
  errorCode: string;
}

export interface INominee {
  firstName: string;
  lastName: string;
  mpNumber: string;
  emailAddress: string;
}

export interface IActiveScreen {
  screenName: string;
  returnToScreen?: string;
  title?: string;
  data?: any;
  previousScreen?: string;
}

export interface IActiveRedemption {
  perkBenefitId: number
}