import cxs from "cxs";
import ContentViewer from "../../../ContentViewer";
import { ContentStateType } from "../../../../state/InitialState";
import { useContext } from "react";
import { ContentContext } from "../../../../context/ContentContext";
import "./styles.scss";

const MPArrivingSoon = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { content, backgroundColorToken, ctaLink, headerText } = contentState?.Content[3]?.perksLanding?.perksLaunch || {};
  const defaultHeaderText = "Arriving soon: Milestones and Perks";
  const defaultCtaTitle = "Learn more about perks";

  const styles = {
    arrivingSoon: cxs({
      backgroundColor: `var(${backgroundColorToken})`,
    }),
  };
  return (
    <div className={`arriving-soon-container ${styles.arrivingSoon}`}>
      <h4 className={`arriving-soon-title`} data-testid="arriving-soon-title">
        {headerText || defaultHeaderText}
      </h4>
      <ContentViewer content={content || ""} isPage={false} />
      <a className={`arriving-soon-cta-link`} href={ctaLink?.href}>{ctaLink?.title || defaultCtaTitle}</a>
    </div>
  );
};

export default MPArrivingSoon;
