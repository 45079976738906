import "./App.scss";
import { Route, Routes } from "react-router-dom";
import "./webcomponents";
import Spinner from "./components/Spinner";
import { reactPlugin } from "./services/AppInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import MVPGoldNominationPage from "./components/MVPGoldNominationPage";
import {
  MVPGoldFormPath,
  ThankYouPagePath,
  MPChoiceProductName,
  AvisStatusMatchProductName,
  ABGFormPath,
  MP100KPath,
  ABGConfirmationPath,
  Perks,
} from "./helpers/constants";
import MPThankYouPage from "./components/MPThankYouPage/MPThankYouPage";
import mountHeaderFooter, { isFromMobileWebView } from "./services/header-footer/index";
import UserAccountContext from "./context/UserAccountContext";
import ABGContainer from "./components/ABGContainer";
import ABGConfirmationPage from "./components/ABGConfirmationPage";
import ProtectedRoute from "./components/ProtectedRoute";
import MP100KContainer from "./components/MP100KContainer/MP100KContainer";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import MPPerksPage from "./components/MPPerksPage";
import { useEffect } from "react";
import ContentProvider from "./context/ContentContext";
import { PerksProvider } from "./context/PerksContext";
import CustomLoadingSpinner from "./components/Spinner/CustomSpinner";

const App = (props: { data: any }) => {
  useEffect(() => {
    mountHeaderFooter(document.getElementById("alaska-header"), document.getElementById("alaska-footer"));
  }, []);

  let mileagePlanData = props.data;
  let mileagePlanNumber = mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber;

  // Product Access Levels:
  // All routes except Avis Status Match T&Cs behind User Auth
  // Avis Status Match --> MVP, Gold, 75k, 100k
  // MP Choice --> 100k & have not made choice yet
  // if (mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber !== "") {
  if (mileagePlanNumber !== "") {
    return (
      <UserAccountContext.Provider value={mileagePlanData}>
        <Routes>
          <Route
            path={MP100KPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature="100k_choice">
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MP100KContainer />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={MVPGoldFormPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature="100k_choice">
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MVPGoldNominationPage />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={ThankYouPagePath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature="100k_choice">
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MPThankYouPage />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={ABGFormPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={AvisStatusMatchProductName}>
                <ABGContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path={Perks}
            element={
              <OptimizelyFeature feature="perks">
                {(isEnabled, variablesDisplayPerks) => (
                  <ProtectedRoute data={mileagePlanData.accountState} product={Perks} isEnabled={isEnabled}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                      <ContentProvider>
                        <PerksProvider>
                          <MPPerksPage />
                        </PerksProvider>
                      </ContentProvider>
                    </AppInsightsContext.Provider>
                  </ProtectedRoute>
                )}
              </OptimizelyFeature>
            }
          />
          <Route
            path={ABGConfirmationPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={AvisStatusMatchProductName}>
                <ABGConfirmationPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAccountContext.Provider>
    );
  } else {
    return isFromMobileWebView() ? <CustomLoadingSpinner /> : <Spinner />;
  }
};

export default App;
