import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { getIcon } from "../../../../../helpers/getIcon";
import { ContentStateType } from "../../../../../state/InitialState";
import { ContentContext } from "../../../../../context/ContentContext";

export interface PerkBenefitItemProps {
    name: string;
    iconId: number;
    items?: string[];
}

const PerksBenefitItem: React.FC<PerkBenefitItemProps> = (props) => {
    const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const [perkName, setPerkName] = useState("");
    const [perkCat, setPerkCat] = useState("");

    useEffect(() => {
        if (contentState) {
            let catname = getIcon(Number(props.iconId), list || [], defaultCategory, defaultIconName).name;
            setPerkName(catname);
            let cattype = getIcon(Number(props.iconId), list || [], defaultCategory, defaultIconName).category;
            setPerkCat(cattype);
        }
    }, [contentState]);

    return (
        <>
            <div className="benefit-item-container" data-testid="perk-benefit-item">
                <ul className="name">
                    <li className="bullet-item">{props.name}</li>
                </ul>
            </div>
            {props.items && props.items.length > 0 && (
                <ul className="sub-items-list">
                    {props.items.map((item, index) => (
                        <li key={index} className="sub-item">
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default PerksBenefitItem;
