import axios from "axios";
import {IEnrollPerksRequest, IFulfillmentPerkDetails, IPerkBenefit} from "../types/perks";
import {AccountStateType} from "../state/InitialState";
import { EnrollPerksRequest } from "./MPPerksService";


export type PerksFulfillmentRequest = IEnrollPerksRequest &
    {
      fulfillmentDate : string
    }

const baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/1/Retain/MileagePlan/mpchoice/api`;

//this endpoint is only for perks that require user action to fulfill
export const PerksSelectionService = async (enrollPerkRequest : IEnrollPerksRequest, token : string) =>
{
    const fulfillPerksURL = `${baseURL}/MemberPerks/SelectPerk`
    try{
        return await axios.post(
            fulfillPerksURL,
            {...enrollPerkRequest},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY as string,
                },
            }
        );
    }
    catch (error) {
        throw error
    }
}


export const PerksFulfillmentService = async (fulfillPerkRequest : PerksFulfillmentRequest, token : string) => 
{
    const fulfillPerksURL = `${baseURL}/MemberPerks/FulfillPerk`
    try{
        return await axios.post(
                fulfillPerksURL,
          {...fulfillPerkRequest},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY as string,
                },
            }
        );
    }
    catch (error) {
        throw error
    }
}


