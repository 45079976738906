import "./styles.scss";

interface IProps {
  cData: { hText: string; cHead: any; cText: string[]; cIconCategory: any; cIconName: any; id: any; supID: any };
  text: any;
  setData: (e: any) => void;
}


const MPBenefitChoiceCard = (props: IProps) => {
  const { hText, cHead, cText, cIconCategory, cIconName, id, supID } = props.cData;
  const setData = props.setData;

  interface IBenefitIdMapper {
    [key: string]: number;
  }
  const benefitDictionary: IBenefitIdMapper = {
    "Alaska Lounge+ membership": 1,
    "Complimentary Wi-fi for a year": 2,
    "50,000 Bonus miles": 3,
    "Gift a MVP Gold status": 4,
    "Complimentary CLEAR® Plus membership": 5
  };
  const handleChange = (e: any) => {
    setData(benefitDictionary[e]);
    console.log("CHOSEN BENEFIT", benefitDictionary[e], e)
  };
  const cTexts: any[] = [];

  cText.forEach((data) => {
    cTexts.push(<p className="card-text" key={data}>{data}<br></br><br></br></p>)
  })

  function getCardTitle() {
    return hText;
  }

  return (
    <>
      <div className="card mb-4">
        <div className="card-header">
          <div className="card-radio">
            <label> 
              <span className="card-radio-button">             
              <input type="radio"
              data-testid={"flexRadioDefault1" + id}
              name="flexRadioDefault"
              id={"flexRadioDefault1" + id}
              value="yes"
              onClick={() => handleChange(hText)} /> 
              </span>
              <span className="card-radio-title"> {getCardTitle()}<sup>{supID}</sup></span>
            </label>
          </div>
          <div className="card-icon">
            <auro-icon id="cardIcon"
              category={cIconCategory}
              name={cIconName}
              customcolor>
            </auro-icon>
          </div>

        </div>
        <div className="card-body">
          <p className="card-head">{cHead}</p>
          {cTexts}
        </div>
      </div>
    </>
  );
};

export default MPBenefitChoiceCard;
